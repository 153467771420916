const administration = {
  header: 'Администрирование',
  items: [
    {
      title: 'Общие',
      list: [
        {
          name: 'Виды БД',
          route: { name: 'CustomerSettingsDatabaseTypes' },
          controllerName: 'CustomerSettingsDatabaseTypes',
        },
        {
          name: 'Шаблоны заявок',
          route: { name: 'SupportReqTemplates' },
          controllerName: 'SupportReqTemplates',
        },
        {
          name: 'Меню справки',
          route: { name: 'KrishaHelpMenu' },
          controllerName: 'KrishaHelpMenu',
        },
      ],
    },

    {
      title: 'Почта',
      list: [
        {
          name: 'Сообщения с почты',
          route: { name: 'MailBoxesMessages' },
          controllerName: 'MailBoxesMessages',
        },
        {
          name: 'Почтовые ящики',
          route: { name: 'MailBoxes' },
          controllerName: 'MailBoxes',
        },
        {
          name: 'Правила для почтовых ящиков',
          route: { name: 'MailBoxesRules' },
          controllerName: 'MailBoxesRules',
        },
      ],
    },

    {
      title: 'Колл-центр',
      list: [
        {
          name: 'Группы шаблонов ответов для пропущенных звонков',
          route: { name: 'MissedCallTemplatesGroups' },
          controllerName: 'MissedCallTemplatesGroups',
        },
        {
          name: 'Шаблоны ответов для пропущенных звонков',
          route: { name: 'MissedCallTemplates' },
          controllerName: 'MissedCallTemplates',
        },
        {
          name: 'Базы для колцентра',
          route: { name: 'CallCenterBases' },
          controllerName: 'CallCenterBases',
        },
        {
          name: 'Соответствие баз и телефонов для роботов',
          route: { name: 'BasePhoneMatching' },
          controllerName: 'BasePhones',
        },
        {
          name: 'Журнал изменений соответствий баз и телефонов',
          route: { name: 'BasePhonesChangeLog' },
          controllerName: 'BasePhonesChangeLog',
        },
        {
          name: 'Журнал проверки заявок КЦ',
          route: { name: 'RequestsVerificationsReport' },
          controllerName: 'RequestsVerificationsReport',
        },
        {
          name: 'Сводный отчет по ошибкам КЦ',
          route: { name: 'RequestsVerificationsSummaryReport' },
          controllerName: 'RequestsVerificationsSummaryReport',
        },
      ],
    },

    {
      title: 'Сотрудники',
      list: [
        {
          name: 'Роли сотрудников',
          route: { name: 'Roles' },
          controllerName: 'Roles',
        },
        {
          name: 'Список сотрудников',
          route: { name: 'AccountEmployees' },
          controllerName: 'AccountEmployees',
        },
        {
          name: 'Сотрудники, которым нельзя писать в заявку КСП',
          route: { name: 'AccountsToBlockClientMessages' },
          controllerName: 'AccountsToBlockClientMessages',
        },
        {
          name: 'Подразделения',
          route: { name: 'Departments' },
          controllerName: 'Departments',
        },
        {
          name: 'Отчет по выполненным заявкам сотрудников',
          route: { name: 'EmployeeReport' },
          controllerName: 'EmployeeReport',
        },
        {
          name: 'Отчет по текущим заявкам сотрудника в работе',
          route: { name: 'RequestsReport' },
          controllerName: 'RequestsReport',
        },
        {
          name: 'Отчет по пулам',
          route: { name: 'PoolsStatReport' },
          controllerName: 'PoolsStatReport',
        },
        {
          name: 'Рабочее время сотрудников',
          route: { name: 'AccountWorkSchedules' },
          controllerName: 'AccountWorkSchedules',
        },
      ],
    },

    {
      title: 'Настройки 1С',
      list: [
        {
          name: 'Версии клиента 1С',
          route: { name: 'Launcher1CVersions' },
          controllerName: 'Launcher1CVersions',
        },
        {
          name: 'Настройка платформ',
          route: { name: 'LauncherPlatforms' },
          controllerName: 'LauncherPlatforms',
        },
        {
          name: 'Конфигурации',
          route: { name: 'TypeBases1C' },
          controllerName: 'TypeBases1C',
        },
        {
          name: 'Базы 1С',
          route: { name: 'Bases1C' },
          controllerName: 'Bases1C',
        },
        {
          name: 'Логирование изменений в базах 1С',
          route: { name: 'Base1CLog' },
          controllerName: 'Base1CLog',
        },
        {
          name: 'Подключения по умолчанию сетевой папки',
          route: { name: 'FolderDefaultConnections' },
          controllerName: 'FolderDefaultConnections',
        },
        {
          name: 'Хосты',
          route: { name: 'Hosts' },
          controllerName: 'Hosts',
        },
        {
          name: 'Сервера',
          route: { name: 'Servers' },
          controllerName: 'Servers',
        },
        {
          name: 'Пин-коды 1с',
          route: { name: 'OneCPinCodes' },
          controllerName: 'OneCPinCodes',
        },
      ],
    },

    {
      title: 'Маршрутизация',
      list: [
        {
          name: 'Маршруты',
          route: { name: 'SupportRoutes' },
          controllerName: 'SupportRoutes',
        },
        {
          name: 'Статусы',
          route: { name: 'SupportInitiationStatuses' },
          controllerName: 'SupportInitiationStatuses',
        },
        {
          name: 'Типы заявок',
          route: { name: 'SupportGisTypes' },
          controllerName: 'SupportGisTypes',
        },
      ],
    },

    {
      title: 'Отчеты',
      list: [
        {
          name: 'Анализ заявок ОСС и ОСП по дням месяца за период',
          route: {
            name: 'StructureApplicationsTimeDayReport',
          },
          controllerName: 'StructureApplicationsTimeDayReport',
        },
        {
          name: 'Анализ заявок ОСС и ОСП по часам в дне за период',
          route: {
            name: 'StructureApplicationsTimeHoursReport',
          },
          controllerName: 'StructureApplicationsTimeHoursReport',
        },
        {
          name: 'Отчет по мониторингу ГИС ЖКХ запросы ОСЗН',
          route: { name: 'DebtRequestsReport' },
          controllerName: 'DebtRequestsReport',
        },
        {
          name: 'Сводный отчет по пробитию чеков орандж - дата',
          route: { name: 'PaymentsVsPaychecksReport' },
          controllerName: 'PaymentsVsPaychecksReport',
        },
        {
          name: 'Отчет по ошибкам формирования чеков',
          route: { name: 'PaychecksErrorsReport' },
          controllerName: 'PaychecksErrorsReport',
        },
        {
          name: 'Сводный паспорт выгрузки в ГИС ЖКХ',
          route: { name: 'GisExportCommonStatisticsReport' },
          controllerName: 'GisExportCommonStatisticsReport',
        },
        {
          name: 'Статистика фрагментации индексов',
          route: { name: 'DbFragmentationsReport' },
          controllerName: 'DbFragmentationsReport',
        },
      ],
    },

    {
      title: 'Интеграции',
      list: [
        {
          name: 'Интеграция с "Дома АИ"',
          route: { name: 'DomaAiIntegration' },
          controllerName: 'DomaAiIntegration',
        },
      ],
    },

    {
      title: 'Поверка приборов',
      list: [
        {
          name: 'Отчет о заявках партнеров',
          route: { name: 'PartnersRequestsReport' },
          controllerName: 'PartnersRequestsReport',
        },
        {
          name: 'Результаты ответов на звонки о необходимости поверить ПУ',
          route: { name: 'MeterRecheckCallsReport' },
          controllerName: 'MeterRecheckCallsReport',
        },
        {
          name: 'Сводный отчет по датам поверки приборов учета',
          route: { name: 'MetersCheckupReport' },
          controllerName: 'MetersCheckupReport',
        },
      ],
    },
  ],
};

const monitoring = {
  header: 'Мониторинг',
  items: [
    {
      title: 'Отчеты',
      list: [
        {
          name: 'Отчет по заявкам ИС',
          route: { name: 'ReportISRequests' },
          controllerName: 'ReportISRequests',
        },
        {
          name: 'Отчет по заявкам АДС во всех базах',
          route: { name: 'AdsRequestsPerformingReport' },
          controllerName: 'AdsRequestsPerformingReport',
        },
        {
          name: 'Сводный отчет по очереди заданий',
          route: { name: 'RequestQueryReport' },
          controllerName: 'RequestQueryReport',
        },
        {
          name: 'Журнал авторизации',
          route: { name: 'SessionsLog' },
          controllerName: 'SessionsLog',
        },
        {
          name: 'Отчет по регистрациям',
          route: { name: 'RegistrationsReport' },
          controllerName: 'RegistrationsReport',
        },
        {
          name: 'Отчет по принятым звонкам сотрудников',
          route: { name: 'EmployeeCallReport' },
          controllerName: 'EmployeeCallReport',
        },
        {
          name: 'Отчет по принятым звонкам сотрудников за день',
          route: { name: 'EmployeeCallDayReport' },
          controllerName: 'EmployeeCallDayReport',
        },
        {
          name: 'Отчет по принятым звонкам за день по клиентам',
          route: { name: 'CustomersCallDayReport' },
          controllerName: 'CustomersCallDayReport',
        },
        {
          name: 'Отчет по статистике звонков за период',
          route: { name: 'CallDayStatisticsReport' },
          controllerName: 'CallDayStatisticsReport',
        },
      ],
    },
    {
      title: 'СКУД',
      list: [
        {
          name: 'Аккаунты АТС домофонов',
          route: { name: 'AtsAccess' },
          controllerName: 'AtsAccess',
        },
      ],
    },
    {
      title: 'Платежи и мобильное приложение',
      list: [
        {
          name: 'Список мобильных пользователей по базам',
          route: { name: 'MobileUsersByDatabasesReport' },
          controllerName: 'MobileUsersByDatabasesReport',
        },
        {
          name: 'Отчет по мобильным пользователям сводный',
          route: { name: 'MobileUsersByDatabasesFullReport' },
          controllerName: 'MobileUsersByDatabasesFullReport',
        },
        {
          name: 'Отчет по реестрам оплат ЛК и МП',
          route: { name: 'PaymentsRegistryStatsReport' },
          controllerName: 'PaymentsRegistryStatsReport',
        },
        {
          name: 'Отчет по реестрам оплат ЛК и МП помесячно',
          route: { name: 'PaymentsRegistryReport' },
          controllerName: 'PaymentsRegistryReport',
        },
        {
          name: 'Отчет по количеству активных пользователей в МП и ЛК',
          route: { name: 'MobileUsersActivityReport' },
          controllerName: 'MobileUsersActivityReport',
        },
        {
          name: 'Сводный отчет по подключенным лиц. счетам МОЕ',
          route: { name: 'MoeAccountsReport' },
          controllerName: 'MoeAccountsReport',
        },
      ],
    },
    {
      title: 'Страхование',
      list: [
        {
          name: 'Базы данных со страхованием',
          route: { name: 'DatabasesWithInsurances' },
          controllerName: 'DatabasesWithInsurances',
        },
        {
          name: 'Отчет по страхованию',
          route: { name: 'InsuranceReport' },
          controllerName: 'InsuranceReport',
        },
      ],
    },
    {
      title: 'Показания приборов учета',
      list: [
        {
          name: 'Cбор данных показаний - фотографии',
          route: { name: 'MeterValuesReaderStatistic' },
          controllerName: 'MeterValuesReaderStatistic',
        },
        {
          name: 'Заявки на поверку ИПУ',
          route: { name: 'MeterRecheckRequests' },
          controllerName: 'MeterRecheckRequests',
        },
      ],
    },
  ],
};

const customers = {
  header: 'Клиенты',
  items: [
    {
      // title: 'Общие',
      list: [
        {
          name: 'Аккаунты',
          route: { name: 'Accounts' },
          controllerName: 'Accounts',
        },
        {
          name: 'Список должников',
          route: { name: 'DebtorList' },
          controllerName: 'DebtorList',
        },
        {
          name: 'Клиенты',
          route: { name: 'Customers' },
          controllerName: 'Customers',
        },
        {
          name: 'Сайты клиентов',
          route: { name: 'Sites' },
          controllerName: 'Sites',
        },
        {
          name: 'Создание клиента',
          route: { name: 'CustomerCreate' },
          controllerName: 'Customers',
        },
        {
          name: 'Создание договоров',
          route: { name: 'ContractCreate' },
          controllerName: 'OneCContracts',
        },
        {
          name: 'Клиенты, по которым не контролировать долги',
          route: { name: 'IgnoredCustomers' },
          controllerName: 'IgnoredCustomers',
        },
        {
          name: 'Базы АДС',
          route: { name: 'AdsBases' },
          controllerName: 'AdsBases',
        },
        {
          name: 'Настройки сайтов',
          route: { name: 'SiteSettings' },
          controllerName: 'Sites',
        },
        {
          name: 'Отчет по выключению десктопных АДС',
          route: { name: 'AdsDesktopReport' },
          controllerName: 'AdsDesktopReport',
        },
        {
          name: 'Вопросы для чат-бота',
          route: { name: 'ChatbotQuestions' },
          controllerName: 'ChatbotQuestions',
        },
        {
          name: 'Ответы на вопросы для чат-бота',
          route: { name: 'ChatbotAnswers' },
          controllerName: 'ChatbotAnswers',
        },
        {
          name: 'Стартовые страницы',
          route: { name: 'StartPages' },
          controllerName: 'StartPages',
        },
        {
          name: 'Дерево решений по тех. поддержке',
          route: { name: 'TechnicalSupportHelpTree' },
          controllerName: 'TechnicalSupportHelpTree',
        },
        {
          name: 'Тарифы на звонки',
          route: { name: 'TariffCalls' },
          controllerName: 'TariffCalls',
        },
      ],
    },
  ],
};

const telemarketing = {
  header: 'Телемаркетинг',
  items: [
    {
      list: [
        {
          name: 'Задания по телемаркетингу',
          route: { name: 'TelemarketingTasks' },
          controllerName: 'TelemarketingTasks',
        },
        {
          name: 'Задания по телемаркетингу для текущего пользователя',
          route: { name: 'TelemarketingTasksForCurrentUser' },
          controllerName: 'TelemarketingTasksForCurrentUser',
        },
        {
          name: 'Множественное создание задания по телемаркетингу',
          route: { name: 'TelemarketingTasksCreateMany' },
          controllerName: 'TelemarketingTasks',
        },
        {
          name: 'Организации',
          route: { name: 'Organizations' },
          controllerName: 'Organizations',
        },
        {
          name: 'Причины отказа для телемаркетинга',
          route: { name: 'TelemarketingTasksRejectionReasons' },
          controllerName: 'TelemarketingTasksRejectionReasons',
        },
        {
          name: 'Звонки по телемаркетингу',
          route: { name: 'SipCalls' },
          controllerName: 'SipCalls',
        },
        {
          name: 'Логи отправки писем',
          route: { name: 'SendMailHistory' },
          controllerName: 'SendMailHistory',
        },
        {
          name: 'Вопросы для чек-листа по телемаркетингу',
          route: { name: 'TelemarketingCheckListQuestions' },
          controllerName: 'TelemarketingCheckListQuestions',
        },
      ],
    },
    {
      title: 'Задачи',
      list: [
        {
          name: 'Продукты телемаркетинга',
          route: { name: 'TelemarketingProducts' },
          controllerName: 'TelemarketingProducts',
        },
        {
          name: 'Скрипты телемаркетинга',
          route: { name: 'TelemarketingScripts' },
          controllerName: 'TelemarketingScripts',
        },
        {
          name: 'Вопросы по телемаркетингу',
          route: { name: 'TelemarketingQuestions' },
          controllerName: 'TelemarketingQuestions',
        },
      ],
    },
    {
      title: 'Отчеты',
      list: [
        {
          name: 'Отчет по телемаркетингу',
          route: { name: 'TelemarketingReport' },
          controllerName: 'TelemarketingReport',
        },
        {
          name: 'Отчет по чек-листам телемаркетинга',
          route: { name: 'TelemarketingCheckListReport' },
          controllerName: 'TelemarketingCheckListReport',
        },
        {
          name: 'Отчет по организациям',
          route: { name: 'OrganizationsReport' },
          controllerName: 'OrganizationsReport',
        },
        {
          name: 'Отчет по выполнению заданий по ТМ',
          route: { name: 'TelemarketingPerformingReport' },
          controllerName: 'TelemarketingPerformingReport',
        },
      ],
    },
  ],
};

const requests = {
  header: 'Заявки',
  items: [
    {
      // title: 'Общие',
      list: [
        {
          name: 'Заявки',
          route: { name: 'SupportRequests' },
          controllerName: 'SupportRequests',
        },
        {
          name: 'Мои заявки',
          route: { name: 'SupportRequestsForCurrentUser' },
        },
      ],
    },
  ],
};

const publishingApplications = {
  header: 'Публикация приложений',
  items: [
    {
      list: [
        {
          name: 'Публикуемые проекты',
          route: { name: 'DeployProject' },
        },
        {
          name: 'Проекты',
          route: { name: 'Deploy' },
        },
        {
          name: 'Кастомные периодические задачи',
          route: { name: 'CustomPeriodicTasks' },
          controllerName: 'CustomPeriodicTasks',
        },
        {
          name: 'Логи кастомных переодических задач',
          route: { name: 'CustomPeriodicTasksLog' },
          controllerName: 'CustomPeriodicTasksLog',
        },
        {
          name: 'Проекты, использующие ApiProxy',
          route: { name: 'ApiProxyProjects' },
          controllerName: 'ApiProxyProjects',
        },
        {
          name: 'Журнал вызовов API-прокси',
          route: { name: 'ApiProxyLog' },
          controllerName: 'ApiProxyLog',
        },
      ],
    },
  ],
};

export default {
  administration,
  customers,
  monitoring,
  requests,
  telemarketing,
  publishingApplications,
};
